import React from "react"
import PropTypes from "prop-types"

import Layout from "../../components/Layout/Layout"
import GlobalContext from "../../context/GlobalContext"
import { setDataLayer } from "../../helpers/dataLayerSetter"
import { makePerex } from "../../helpers/DataConvertor.helper"
import componentMaker from "../../helpers/ComponentMaker/componentMaker"
import { gTagScriptConsent } from "../../components/Scripts/Gtm.Head"

const GenericContent = ({ path, pageContext }) => {
    const { globalProps, seoData, pageType, dataLayer, globalLabels, brandTheme } = pageContext
    const finalSeoData = seoData || {}
    finalSeoData.title = seoData.metaTitle
    finalSeoData.metaDescription = makePerex(seoData?.metaDescription, 200)
    finalSeoData.category = brandTheme?.brandName
    setDataLayer(dataLayer, finalSeoData, pageType)

    return (
        <GlobalContext labels={globalLabels} brandTheme={brandTheme}>
            <Layout path={path} seoData={seoData} globalProps={globalProps}>
                {componentMaker(pageContext.pageProps?.staticResult?.contentComponents)}
            </Layout>
        </GlobalContext>
    )
}

GenericContent.propTypes = {
    path: PropTypes.string,
    pageContext: PropTypes.shape({
        pageProps: PropTypes.object,
        globalProps: PropTypes.object,
        seoData: PropTypes.object,
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        globalLabels: PropTypes.object,
        brandTheme: PropTypes.object,
    }),
}

/**
 * Adding to Layout ?
 */
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
            />
        </>
    )
}

export default GenericContent
